@use "main.scss";
@use "sass:color";
@use "sass:math";

header {
    background: #fff;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    font-size: $font-large;
    right: 0;
    z-index: 20;
    will-change: height;


    
    nav.mobile {
        display: none;
        position: relative;
        @media screen and (max-width: 670px) {
            display: flex;
        }
        width: clamp(100px, 90vw, 500px);
        $nav-height: 36px;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: 5px auto;
        height: $nav-height;

        justify-content: space-between;
        align-items: center;

        img {
            height: 100%;
        }

        div#HamburgerContainer {
            position: relative;
            width: $nav-height;
            height: 60%;
            z-index: 20;
            border: 1px solid transparent;

            div.hamburger {
                height: 3px;
                border-radius: 2px;
                position: absolute;
                right: 0;
                background: black;
            }
            
            div:nth-child(1) {
                top: 0;
                width: 100%;
                // animation: hamburgerTop 400ms ease-in-out forwards;
            }

            div:nth-child(2) {
                top: 48%;
                width: 75%;
                // animation: hamburgerMid 400ms ease-in-out forwards;
            }

            div:nth-child(3) {
                top: 96%;
                width: 50%;
                // animation: hamburgerBottom 400ms ease-in-out forwards;
            }

            @keyframes hamburgerTop {
                0% {
                    transform-origin: center center;
                    transform: translate(0, 0) rotate(0);
                }
                100% {
                    transform: translate(0, 9px) rotate(135deg);
                }
            }

            @keyframes hamburgerMid {
                0% {
                    transform-origin: center center;
                    transform: translate(0, 0) rotate(0);
                }
                100% {
                    transform: translate(-6.4px, -3.9px) rotate(-135deg);
                }
            }

            @keyframes hamburgerBottom {
                0% {
                    transform-origin: center center;
                    transform: translate(0, 0) rotate(0);
                }
                100% {
                    transform: translate(-2.5px, -4.2px) rotate(225deg);
                }
            }
        }

        div#NavContainer {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: flex-end;
            position: fixed;
            gap: 1em;
            font-size: $font-larger;
            width: clamp(22ch, 40vw, 400px);
            right: 0;
            top: 0;
            height: 100vh;
            background-color: #fff;
            @supports (backdrop-filter: blur(18px)) or (-webkit-backdrop-filter: blur(18px)) {
                background-color: hsla(15, 83%, 54%, 0.75);
                -webkit-backdrop-filter: blur(18px);
                backdrop-filter: blur(18px);
            }
            transform: translateX(110vw);
            transition: 300ms ease-in-out;
            z-index: 15;
            padding: 3em 2em 4em 1em;

            h3 {
                margin: 0;
            }

            div:first-of-type {
                display: flex;
                gap: 1em;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-end;
            }

            div:last-of-type {
                a {
                    text-align: right;
                    text-decoration: none;
                    display: block;
                    color: rgba(0, 0, 0, .7);
                    font-size: $font-medium;
                }
            }
        }

        div#NavContainer.open {
            transform: translateX(0vw);
        }
    }

    nav.desktop {
        position: relative;
        visibility: hidden;
        display: none;
        @media screen and (min-width: 670px) {
            visibility: visible;
            display: flex;
        }
        flex-direction: column;
        top: 0;
        bottom: 0;
        justify-content: space-between;
        align-items: center;
        width: 90vw;
        left: 0;
        right: 0;
        margin: 8px auto;
        padding: 0 1em;
        transition: height 200ms linear;
        height: calc(100px + $font-large * 2 * 1.2);
        will-change: height;

        &.small {
            height: 42px;
        }

        img {
            height: 100px;
            transition: transform 200ms linear;
            flex-grow: 1;
            flex-shrink: 0;
            will-change: transform;
        }

        div {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 3em;

            h3 {
                font-size: $font-large;
                overflow-y: hidden;
                margin-bottom: 0;
                
                span {
                    transition: transform 200ms linear;
                    display: block;
                }
            }

            &.small > h3 > span {
                transform: translateY(-2em);
            }
        }
    }
}