@use "sass:color";
@use "main";

section#Leistungen {
    position: relative;
    background: $background-orange;
    min-height: 60vh;
    height: fit-content;

    div#LeistungenContainer {
        position: relative;
        overflow-x: hidden;
        width: 100vw;
        margin: 2em 0;
        
        div.leistungElement.leistungElement {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            box-sizing: content-box;

            @media screen and (max-width: 690px) {
                $elementWidth: clamp(200px, 80vw, 450px);
                width: $elementWidth;
                padding: 0 calc((100vw - $elementWidth) / 2);
            }

            @media screen and (min-width: 690px) and (max-width: 1300px) {
                $elementWidth: clamp(200px, 40vw, 450px);
                width: $elementWidth;
                padding: 0 calc((50vw - $elementWidth) / 2);
            }

            @media screen and (min-width: 1300px) and (max-width: 1700px) {
                $elementWidth: clamp(200px, 26.67vw, 450px);
                width: $elementWidth;
                padding: 0 calc((33.33vw - $elementWidth) / 2);
            }

            @media screen and (min-width: 1700px) {
                $elementWidth: clamp(200px, 20vw, 450px);
                width: $elementWidth;
                padding: 0 calc((25vw - $elementWidth) / 2);
            }

            & > img {
                // border-radius: 50%;
                width: 60%;
            }

            & > h2 {
                width: 90%;
            }

            & > p {
                width: 75%;
            }
        }
    }
}