@use "main";

section#Hero {
    background-color: $background-blue;
    font-size: clamp($font-humongous, 6.5vw, 110px);
    @media screen and (max-width: 650px) {
        font-size: clamp(0px, 10vw, $font-humongous);
    }
    position: relative;
    height: calc(50vh + 3 * 1.2 * 1em + 180px);

    h1 {
        font-size: inherit;
        position: absolute;
        text-align: right;
        color: hsl(210, 100%, 16%);
        right: 0;
        top: calc(136px + 2 * 1.2 * $font-large);
        @media screen and (max-width: 670px) {
            top: 100px;
        }
        margin: 0 1em 0 0;
    }

    #Boot {
        transform-origin: 9.9% 25.7%;
        animation: wiggleBoat 2s ease-in-out infinite alternate;
    }

    @keyframes wiggleBoat {
        from {
            transform: rotate(-3deg);
        }
        to {
            transform: rotate(2deg);
        }
    }
    
    svg {
        position: relative;
        font-size: inherit;
        align-self: flex-start;
        // min-width: 410%;
        // min-width: 1350px;
        max-width: 450vw;
        min-width: 100vw;
        height: 50vh;
        left: 0;
        top: calc(180px + 3 * 1.2 * 1em);

    }
}