@use "main";

section#Referenzen {
    position: relative;
    height: fit-content;
    $topOffset: 20em;
    $actualPadding: 4em;
    margin: -$topOffset 0 0 0;
    padding: $topOffset + $actualPadding 0 0 0;
    background-color: $background-green;
    z-index: 1;

    div.referenzenElement.referenzenElement {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        background: hsla(0, 0%, 100%, 0.75);
        height: 10em;

        font-size: $font-larger;
        width: 17ch;
        max-width: 90vw;
        margin: 20px;
        border-radius: 12px;
        padding: 10px;
        box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

        & > img {
            width: 80%;
            height: 100px;
            object-fit: contain;
        }

        h2 {
            font-size: inherit;
            text-align: center;
        }

        p {
            font-size: $font-medium;
        }

        &:nth-of-type(1) {
            justify-content: center;

            img {
                width: 25%;
                border-radius: 0;
            }
        }
    }
}

img#Waves3 {
    margin: 0 0 0 0;
    z-index: 2;
    width: 700px;
    min-width: 100vw;
    align-self: flex-start;
    height: fit-content;
    object-fit: fill;
}