@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Bold.e1f96d4b.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Light.a13cbb1a.ttf");
  font-weight: Light;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Regular.d585f5c7.ttf");
  font-weight: normal;
}

* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  line-height: 120%;
}

html, body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

section {
  width: 100vw;
  height: 100vh;
}

.kontaktBtn {
  color: #fff;
  z-index: 10;
  background-color: #ea5a2a;
  font-size: 2.48832rem;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.kontaktBtn.mobile {
  box-sizing: content-box;
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  padding: .5em;
  animation: ring 75ms ease-in-out 1s 6 alternate;
  display: block;
  bottom: 10px;
  right: 10px;
}

@media screen and (min-width: 750px) {
  .kontaktBtn.mobile {
    display: none;
  }
}

.kontaktBtn.desktop {
  border-radius: 12px;
  padding: .2em 1em;
  font-size: 2.98598rem;
}

@media screen and (max-width: 750px) {
  .kontaktBtn.desktop {
    display: none;
  }
}

@keyframes ring {
  to {
    transform: rotate(30deg);
  }
}

button {
  border: none;
}

main {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

#Wave1 {
  width: 100vw;
  height: 40vw;
}

footer {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  display: flex;
}

footer a {
  color: #000;
  margin: 1em 0;
  font-size: 1.2rem;
  text-decoration: none;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Bold.e1f96d4b.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Light.a13cbb1a.ttf");
  font-weight: Light;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Regular.d585f5c7.ttf");
  font-weight: normal;
}

* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  line-height: 120%;
}

html, body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

section {
  width: 100vw;
  height: 100vh;
}

.kontaktBtn {
  color: #fff;
  z-index: 10;
  background-color: #ea5a2a;
  font-size: 2.48832rem;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.kontaktBtn.mobile {
  box-sizing: content-box;
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  padding: .5em;
  animation: ring 75ms ease-in-out 1s 6 alternate;
  display: block;
  bottom: 10px;
  right: 10px;
}

@media screen and (min-width: 750px) {
  .kontaktBtn.mobile {
    display: none;
  }
}

.kontaktBtn.desktop {
  border-radius: 12px;
  padding: .2em 1em;
  font-size: 2.98598rem;
}

@media screen and (max-width: 750px) {
  .kontaktBtn.desktop {
    display: none;
  }
}

button {
  border: none;
}

main {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

#Wave1 {
  width: 100vw;
  height: 40vw;
}

footer {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  display: flex;
}

footer a {
  color: #000;
  margin: 1em 0;
  font-size: 1.2rem;
  text-decoration: none;
}

section#Hero {
  height: calc(50vh + 3.6em + 180px);
  background-color: #3d80c240;
  font-size: max(3.58318rem, min(6.5vw, 110px));
  position: relative;
}

@media screen and (max-width: 650px) {
  section#Hero {
    font-size: max(0px, min(10vw, 3.58318rem));
  }
}

section#Hero h1 {
  font-size: inherit;
  text-align: right;
  color: #002952;
  margin: 0 1em 0 0;
  position: absolute;
  top: calc(136px + 3.456rem);
  right: 0;
}

@media screen and (max-width: 670px) {
  section#Hero h1 {
    top: 100px;
  }
}

section#Hero #Boot {
  transform-origin: 9.9% 25.7%;
  animation: wiggleBoat 2s ease-in-out infinite alternate;
}

@keyframes wiggleBoat {
  from {
    transform: rotate(-3deg);
  }

  to {
    transform: rotate(2deg);
  }
}

section#Hero svg {
  font-size: inherit;
  max-width: 450vw;
  min-width: 100vw;
  height: 50vh;
  align-self: flex-start;
  position: relative;
  top: calc(180px + 3.6em);
  left: 0;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Bold.e1f96d4b.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Light.a13cbb1a.ttf");
  font-weight: Light;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Regular.d585f5c7.ttf");
  font-weight: normal;
}

* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  line-height: 120%;
}

html, body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

section {
  width: 100vw;
  height: 100vh;
}

.kontaktBtn {
  color: #fff;
  z-index: 10;
  background-color: #ea5a2a;
  font-size: 2.48832rem;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.kontaktBtn.mobile {
  box-sizing: content-box;
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  padding: .5em;
  animation: ring 75ms ease-in-out 1s 6 alternate;
  display: block;
  bottom: 10px;
  right: 10px;
}

@media screen and (min-width: 750px) {
  .kontaktBtn.mobile {
    display: none;
  }
}

.kontaktBtn.desktop {
  border-radius: 12px;
  padding: .2em 1em;
  font-size: 2.98598rem;
}

@media screen and (max-width: 750px) {
  .kontaktBtn.desktop {
    display: none;
  }
}

button {
  border: none;
}

main {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

#Wave1 {
  width: 100vw;
  height: 40vw;
}

footer {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  display: flex;
}

footer a {
  color: #000;
  margin: 1em 0;
  font-size: 1.2rem;
  text-decoration: none;
}

header {
  width: 100vw;
  z-index: 20;
  will-change: height;
  background: #fff;
  font-size: 1.44rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

header nav.mobile {
  width: max(100px, min(90vw, 500px));
  height: 36px;
  justify-content: space-between;
  align-items: center;
  margin: 5px auto;
  display: none;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (max-width: 670px) {
  header nav.mobile {
    display: flex;
  }
}

header nav.mobile img {
  height: 100%;
}

header nav.mobile div#HamburgerContainer {
  width: 36px;
  height: 60%;
  z-index: 20;
  border: 1px solid #0000;
  position: relative;
}

header nav.mobile div#HamburgerContainer div.hamburger {
  height: 3px;
  background: #000;
  border-radius: 2px;
  position: absolute;
  right: 0;
}

header nav.mobile div#HamburgerContainer div:nth-child(1) {
  width: 100%;
  top: 0;
}

header nav.mobile div#HamburgerContainer div:nth-child(2) {
  width: 75%;
  top: 48%;
}

header nav.mobile div#HamburgerContainer div:nth-child(3) {
  width: 50%;
  top: 96%;
}

@keyframes hamburgerTop {
  0% {
    transform-origin: center;
    transform: translate(0, 0) rotate(0);
  }

  100% {
    transform: translate(0, 9px)rotate(135deg);
  }
}

@keyframes hamburgerMid {
  0% {
    transform-origin: center;
    transform: translate(0, 0) rotate(0);
  }

  100% {
    transform: translate(-6.4px, -3.9px)rotate(-135deg);
  }
}

@keyframes hamburgerBottom {
  0% {
    transform-origin: center;
    transform: translate(0, 0) rotate(0);
  }

  100% {
    transform: translate(-2.5px, -4.2px)rotate(225deg);
  }
}

header nav.mobile div#NavContainer {
  width: max(22ch, min(40vw, 400px));
  height: 100vh;
  z-index: 15;
  background-color: #fff;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1em;
  padding: 3em 2em 4em 1em;
  font-size: 1.728rem;
  transition: all .3s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  transform: translateX(110vw);
}

@supports (backdrop-filter: blur(18px)) or (-webkit-backdrop-filter: blur(18px)) {
  header nav.mobile div#NavContainer {
    -webkit-backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
    backdrop-filter: blur(18px);
    background-color: #eb5928bf;
  }
}

header nav.mobile div#NavContainer h3 {
  margin: 0;
}

header nav.mobile div#NavContainer div:first-of-type {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 1em;
  display: flex;
}

header nav.mobile div#NavContainer div:last-of-type a {
  text-align: right;
  color: #000000b3;
  font-size: 1.2rem;
  text-decoration: none;
  display: block;
}

header nav.mobile div#NavContainer.open {
  transform: translateX(0);
}

header nav.desktop {
  visibility: hidden;
  width: 90vw;
  height: calc(100px + 3.456rem);
  will-change: height;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 8px auto;
  padding: 0 1em;
  transition: height .2s linear;
  display: none;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (min-width: 670px) {
  header nav.desktop {
    visibility: visible;
    display: flex;
  }
}

header nav.desktop.small {
  height: 42px;
}

header nav.desktop img {
  height: 100px;
  will-change: transform;
  flex-grow: 1;
  flex-shrink: 0;
  transition: transform .2s linear;
}

header nav.desktop div {
  justify-content: flex-end;
  align-items: center;
  gap: 3em;
  display: flex;
}

header nav.desktop div h3 {
  margin-bottom: 0;
  font-size: 1.44rem;
  overflow-y: hidden;
}

header nav.desktop div h3 span {
  transition: transform .2s linear;
  display: block;
}

header nav.desktop div.small > h3 > span {
  transform: translateY(-2em);
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Bold.e1f96d4b.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Light.a13cbb1a.ttf");
  font-weight: Light;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Regular.d585f5c7.ttf");
  font-weight: normal;
}

* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  line-height: 120%;
}

html, body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

section {
  width: 100vw;
  height: 100vh;
}

.kontaktBtn {
  color: #fff;
  z-index: 10;
  background-color: #ea5a2a;
  font-size: 2.48832rem;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.kontaktBtn.mobile {
  box-sizing: content-box;
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  padding: .5em;
  animation: ring 75ms ease-in-out 1s 6 alternate;
  display: block;
  bottom: 10px;
  right: 10px;
}

@media screen and (min-width: 750px) {
  .kontaktBtn.mobile {
    display: none;
  }
}

.kontaktBtn.desktop {
  border-radius: 12px;
  padding: .2em 1em;
  font-size: 2.98598rem;
}

@media screen and (max-width: 750px) {
  .kontaktBtn.desktop {
    display: none;
  }
}

button {
  border: none;
}

main {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

#Wave1 {
  width: 100vw;
  height: 40vw;
}

footer {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  display: flex;
}

footer a {
  color: #000;
  margin: 1em 0;
  font-size: 1.2rem;
  text-decoration: none;
}

section#Leistungen {
  min-height: 60vh;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #eb592840;
  position: relative;
}

section#Leistungen div#LeistungenContainer {
  width: 100vw;
  margin: 2em 0;
  position: relative;
  overflow-x: hidden;
}

section#Leistungen div#LeistungenContainer div.leistungElement.leistungElement {
  box-sizing: content-box;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 690px) {
  section#Leistungen div#LeistungenContainer div.leistungElement.leistungElement {
    width: max(200px, min(80vw, 450px));
    padding: 0 calc(50vw + max(200px, min(80vw, 450px)) / -2);
  }
}

@media screen and (min-width: 690px) and (max-width: 1300px) {
  section#Leistungen div#LeistungenContainer div.leistungElement.leistungElement {
    width: max(200px, min(40vw, 450px));
    padding: 0 calc(25vw + max(200px, min(40vw, 450px)) / -2);
  }
}

@media screen and (min-width: 1300px) and (max-width: 1700px) {
  section#Leistungen div#LeistungenContainer div.leistungElement.leistungElement {
    width: max(200px, min(26.67vw, 450px));
    padding: 0 calc(16.665vw + max(200px, min(26.67vw, 450px)) / -2);
  }
}

@media screen and (min-width: 1700px) {
  section#Leistungen div#LeistungenContainer div.leistungElement.leistungElement {
    width: max(200px, min(20vw, 450px));
    padding: 0 calc(12.5vw + max(200px, min(20vw, 450px)) / -2);
  }
}

section#Leistungen div#LeistungenContainer div.leistungElement.leistungElement > img {
  width: 60%;
}

section#Leistungen div#LeistungenContainer div.leistungElement.leistungElement > h2 {
  width: 90%;
}

section#Leistungen div#LeistungenContainer div.leistungElement.leistungElement > p {
  width: 75%;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Bold.e1f96d4b.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Light.a13cbb1a.ttf");
  font-weight: Light;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Regular.d585f5c7.ttf");
  font-weight: normal;
}

* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  line-height: 120%;
}

html, body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

section {
  width: 100vw;
  height: 100vh;
}

.kontaktBtn {
  color: #fff;
  z-index: 10;
  background-color: #ea5a2a;
  font-size: 2.48832rem;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.kontaktBtn.mobile {
  box-sizing: content-box;
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  padding: .5em;
  animation: ring 75ms ease-in-out 1s 6 alternate;
  display: block;
  bottom: 10px;
  right: 10px;
}

@media screen and (min-width: 750px) {
  .kontaktBtn.mobile {
    display: none;
  }
}

.kontaktBtn.desktop {
  border-radius: 12px;
  padding: .2em 1em;
  font-size: 2.98598rem;
}

@media screen and (max-width: 750px) {
  .kontaktBtn.desktop {
    display: none;
  }
}

button {
  border: none;
}

main {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

#Wave1 {
  width: 100vw;
  height: 40vw;
}

footer {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  display: flex;
}

footer a {
  color: #000;
  margin: 1em 0;
  font-size: 1.2rem;
  text-decoration: none;
}

section#BrandIdentity {
  z-index: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2em;
  padding: 1em;
  font-size: 1.728rem;
  display: flex;
  position: relative;
}

section#BrandIdentity .identityElement {
  font-size: inherit;
  min-width: 300px;
  max-width: 900px;
  width: 100%;
  z-index: 10;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  opacity: 0;
  background-color: #fff;
  border-radius: 12px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
  padding: 1em;
  font-size: 1.728rem;
  display: flex;
  position: relative;
  box-shadow: 2px 2px 12px 5px #142b421a;
}

section#BrandIdentity .identityElement > img {
  font-size: inherit;
  width: max(70px, min(30vw, 250px));
}

section#BrandIdentity .identityElement > div {
  position: relative;
}

section#BrandIdentity .identityElement h2 {
  margin: 0 0 1em;
  font-size: 1.728rem;
  overflow: scroll;
}

section#BrandIdentity .identityElement p {
  font-size: 1.2rem;
  transition: height .7s;
  overflow: hidden;
}

section#BrandIdentity .identityElement p span {
  color: #3e80c1;
}

section#BrandIdentity .identityElement p[detailed] {
  visibility: hidden;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
}

@media screen and (min-width: 650px) {
  section#BrandIdentity .identityElement {
    box-shadow: none;
    flex-direction: row;
  }

  section#BrandIdentity .identityElement:nth-child(2n) {
    flex-flow: row-reverse;
  }

  section#BrandIdentity .identityElement p {
    display: none;
  }

  section#BrandIdentity .identityElement p[detailed] {
    visibility: visible;
    display: inline;
    position: relative;
  }
}

section#BrandIdentity .identityElement.visible:nth-of-type(0) {
  animation: showIdentityElement .4s ease-in-out -300ms forwards;
}

section#BrandIdentity .identityElement.visible:nth-of-type(1) {
  animation: showIdentityElement .4s ease-in-out forwards;
}

section#BrandIdentity .identityElement.visible:nth-of-type(2) {
  animation: showIdentityElement .4s ease-in-out .3s forwards;
}

section#BrandIdentity .identityElement.visible:nth-of-type(3) {
  animation: showIdentityElement .4s ease-in-out .6s forwards;
}

section#BrandIdentity .identityElement.visible:nth-of-type(4) {
  animation: showIdentityElement .4s ease-in-out .9s forwards;
}

@keyframes showIdentityElement {
  from {
    opacity: 0;
    transform: translateY(50px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Bold.e1f96d4b.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Light.a13cbb1a.ttf");
  font-weight: Light;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Regular.d585f5c7.ttf");
  font-weight: normal;
}

* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  line-height: 120%;
}

html, body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

section {
  width: 100vw;
  height: 100vh;
}

.kontaktBtn {
  color: #fff;
  z-index: 10;
  background-color: #ea5a2a;
  font-size: 2.48832rem;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.kontaktBtn.mobile {
  box-sizing: content-box;
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  padding: .5em;
  animation: ring 75ms ease-in-out 1s 6 alternate;
  display: block;
  bottom: 10px;
  right: 10px;
}

@media screen and (min-width: 750px) {
  .kontaktBtn.mobile {
    display: none;
  }
}

.kontaktBtn.desktop {
  border-radius: 12px;
  padding: .2em 1em;
  font-size: 2.98598rem;
}

@media screen and (max-width: 750px) {
  .kontaktBtn.desktop {
    display: none;
  }
}

button {
  border: none;
}

main {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

#Wave1 {
  width: 100vw;
  height: 40vw;
}

footer {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  display: flex;
}

footer a {
  color: #000;
  margin: 1em 0;
  font-size: 1.2rem;
  text-decoration: none;
}

#Wave2, #Wave3 {
  width: 100vw;
  height: 150px;
  z-index: 2;
  align-self: flex-end;
  position: relative;
}

@media screen and (min-width: 850px) {
  #Wave2, #Wave3 {
    width: 85vw;
  }
}

#Wave3 {
  transform: rotate(180deg);
}

section#Nutzen {
  z-index: 2;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100vw;
  background: #d2e0f0;
  align-self: flex-end;
  padding: 2em 2em 0;
  font-size: 2.0736rem;
  position: relative;
}

@media screen and (min-width: 850px) {
  section#Nutzen {
    width: 85vw;
  }
}

section#Nutzen h1, section#Nutzen h2, section#Nutzen h3 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 850px;
}

section#Nutzen h1 {
  font-size: 3.58318rem;
}

section#Nutzen h2 {
  font-size: 2.48832rem;
}

section#Nutzen h3 {
  font-size: 1.44rem;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Bold.e1f96d4b.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Light.a13cbb1a.ttf");
  font-weight: Light;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Regular.d585f5c7.ttf");
  font-weight: normal;
}

* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  line-height: 120%;
}

html, body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

section {
  width: 100vw;
  height: 100vh;
}

.kontaktBtn {
  color: #fff;
  z-index: 10;
  background-color: #ea5a2a;
  font-size: 2.48832rem;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.kontaktBtn.mobile {
  box-sizing: content-box;
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  padding: .5em;
  animation: ring 75ms ease-in-out 1s 6 alternate;
  display: block;
  bottom: 10px;
  right: 10px;
}

@media screen and (min-width: 750px) {
  .kontaktBtn.mobile {
    display: none;
  }
}

.kontaktBtn.desktop {
  border-radius: 12px;
  padding: .2em 1em;
  font-size: 2.98598rem;
}

@media screen and (max-width: 750px) {
  .kontaktBtn.desktop {
    display: none;
  }
}

button {
  border: none;
}

main {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

#Wave1 {
  width: 100vw;
  height: 40vw;
}

footer {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  display: flex;
}

footer a {
  color: #000;
  margin: 1em 0;
  font-size: 1.2rem;
  text-decoration: none;
}

section#Referenzen {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 1;
  background-color: #14a34940;
  margin: -20em 0 0;
  padding: 24em 0 0;
  position: relative;
}

section#Referenzen div.referenzenElement.referenzenElement {
  height: 10em;
  width: 17ch;
  max-width: 90vw;
  background: #ffffffbf;
  border-radius: 12px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 20px;
  padding: 10px;
  font-size: 1.728rem;
  display: flex;
  box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
}

section#Referenzen div.referenzenElement.referenzenElement > img {
  width: 80%;
  height: 100px;
  object-fit: contain;
}

section#Referenzen div.referenzenElement.referenzenElement h2 {
  font-size: inherit;
  text-align: center;
}

section#Referenzen div.referenzenElement.referenzenElement p {
  font-size: 1.2rem;
}

section#Referenzen div.referenzenElement.referenzenElement:nth-of-type(1) {
  justify-content: center;
}

section#Referenzen div.referenzenElement.referenzenElement:nth-of-type(1) img {
  width: 25%;
  border-radius: 0;
}

img#Waves3 {
  z-index: 2;
  width: 700px;
  min-width: 100vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  object-fit: fill;
  align-self: flex-start;
  margin: 0;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Bold.e1f96d4b.ttf");
  font-weight: bold;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Light.a13cbb1a.ttf");
  font-weight: Light;
}

@font-face {
  font-family: Roboto;
  src: url("RobotoCondensed-Regular.d585f5c7.ttf");
  font-weight: normal;
}

* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  line-height: 120%;
}

html, body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

section {
  width: 100vw;
  height: 100vh;
}

.kontaktBtn {
  color: #fff;
  z-index: 10;
  background-color: #ea5a2a;
  font-size: 2.48832rem;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.kontaktBtn.mobile {
  box-sizing: content-box;
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  padding: .5em;
  animation: ring 75ms ease-in-out 1s 6 alternate;
  display: block;
  bottom: 10px;
  right: 10px;
}

@media screen and (min-width: 750px) {
  .kontaktBtn.mobile {
    display: none;
  }
}

.kontaktBtn.desktop {
  border-radius: 12px;
  padding: .2em 1em;
  font-size: 2.98598rem;
}

@media screen and (max-width: 750px) {
  .kontaktBtn.desktop {
    display: none;
  }
}

button {
  border: none;
}

main {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

#Wave1 {
  width: 100vw;
  height: 40vw;
}

footer {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: #fff;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  display: flex;
}

footer a {
  color: #000;
  margin: 1em 0;
  font-size: 1.2rem;
  text-decoration: none;
}

section#Kontakt {
  height: -webkit-max-content;
  height: max-content;
  background-color: #3d80c240;
  padding: 40px;
  position: relative;
}

section#Kontakt > div {
  width: 80vw;
  max-width: 800px;
  flex-direction: column;
  gap: 40px;
  margin: auto;
  font-size: 1rem;
  display: flex;
  position: relative;
  bottom: 0;
}

section#Kontakt > div h1 {
  grid-area: header;
  margin: 0 0 0 -1ch;
  font-size: 2.98598rem;
}

section#Kontakt > div h3 {
  grid-area: text;
  margin: 0;
  font-size: 1.2rem;
  font-weight: normal;
}

section#Kontakt > div > div {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 40px;
  display: flex;
}

section#Kontakt > div > div > div {
  width: 24ch;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: auto;
  font-size: 1rem;
  display: flex;
}

section#Kontakt > div > div > div img {
  width: 60%;
}

section#Kontakt > div > div > div p, section#Kontakt > div > div > div a {
  color: #000;
  margin-top: 1em;
  text-decoration: none;
}

section#Kontakt > div div:nth-of-type(1) {
  grid-area: nachricht;
}

section#Kontakt > div div:nth-of-type(2) {
  grid-area: tele;
}

section#Kontakt > div div:nth-of-type(3) {
  grid-area: mail;
}

section#Kontakt > div p {
  text-align: center;
  grid-area: abschied;
  margin: 0;
}

section#Kontakt > div button {
  color: #fff;
  z-index: 10;
  width: -webkit-min-content;
  width: min-content;
  background-color: #ea5a2a;
  border-radius: 12px;
  align-self: center;
  padding: .2em 1em;
  font-size: 2.48832rem;
}

/*# sourceMappingURL=index.c826693d.css.map */
