@use "sass:math";

$font-smaller: math.pow(1.2, -2) * 1.2rem;
$font-small: math.pow(1.2, -1) * 1.2rem;
$font-medium: math.pow(1.2, 0) * 1.2rem;
$font-large: math.pow(1.2, 1) * 1.2rem;
$font-larger: math.pow(1.2, 2) * 1.2rem;
$font-big: math.pow(1.2, 3) * 1.2rem;
$font-bigger: math.pow(1.2, 4) * 1.2rem;
$font-huge: math.pow(1.2, 5) * 1.2rem;
$font-humongous: math.pow(1.2, 6) * 1.2rem;

$akzent: hsla(15, 82%, 54%, 1);
$sekundär-orange: hsla(15, 82%, 54%, .6);
$sekundär-blue: hsla(210, 51%, 50%, .6);
$background-blue: hsla(210, 52%, 50%, 0.25);
$background-orange: hsla(15, 83%, 54%, 0.25);
$background-green: hsla(142, 78%, 36%, 0.25);

@font-face {
    font-family: Roboto;
    src: url(../fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf);
    font-weight: Bold;
}
@font-face {
    font-family: Roboto;
    src: url(../fonts/Roboto_Condensed/RobotoCondensed-Light.ttf);
    font-weight: Light;
}
@font-face {
    font-family: Roboto;
    src: url(../fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf);
    font-weight: normal;
}

* {
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
    line-height: 120%;
}

html, body {
    width: 100vw;
    min-height: 100vh;
    margin: 0; 
    padding: 0;
    overflow-x: hidden;
}

section {
    width: 100vw;
    height: 100vh;
}

.kontaktBtn {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: $akzent;
    font-size: $font-bigger;
    color: white;
    z-index: 10;
}

$kontaktBtnToggelWidth: 750px;

.kontaktBtn.mobile {
    bottom: 10px;
    right: 10px;
    display: block;
    border-radius: 50%;
    box-sizing: content-box;
    width: 10vw;
    height: 10vw;
    padding: .5em;

    animation: ring 75ms ease-in-out 1000ms 6 alternate;

    @media screen and (min-width: $kontaktBtnToggelWidth) {
        display: none;
    }
}

.kontaktBtn.desktop {
    border-radius: 12px;
    font-size: $font-huge;
    padding: .2em 1em;

    @media screen and (max-width: $kontaktBtnToggelWidth) {
        display: none;
    }
}

@keyframes ring {
    to {
        transform: rotate(30deg);
    }
}

button {
    border: none;
}

main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

#Wave1 {
    width: 100vw;
    height: 40vw;
}

footer {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
    background: #fff;

    a {
        text-decoration: none;
        font-size: $font-medium;
        color: black;
        margin: 1em 0;
    }
}