@import "main";

section#Kontakt {
    position: relative;
    background-color: $background-blue;
    height: max-content;
    padding: 40px;
    
    & > div {
        bottom: 0;
        width: 80vw;
        max-width: 800px;
        position: relative;
        margin: auto;

        display: flex;
        flex-direction: column;
        gap: 40px;

        font-size: 1rem;

        h1 {
            grid-area: header;
            margin: 0 0 0 -1ch;
            font-size: $font-huge;
        }

        h3 {
            font-weight: normal;
            font-size: $font-medium;
            grid-area: text;
            margin: 0;
        }

        & > div {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 40px;

            & > div {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;
                width: 24ch;
                font-size: 1rem;
                margin: auto;
                
                img {
                    width: 60%;
                }

                p, a {
                    margin-block-start: 1em;
                    text-decoration: none;
                    color: black;
                }
            }
        }

        div:nth-of-type(1) {
            grid-area: nachricht;
        }

        div:nth-of-type(2) {
            grid-area: tele;
        }

        div:nth-of-type(3) {
            grid-area: mail;
        }

        p {
            grid-area: abschied;
            text-align: center;
            margin: 0;
        }

        button {
            background-color: $akzent;
            font-size: $font-bigger;
            color: white;
            z-index: 10;
            width: min-content;
            border-radius: 12px;
            padding: .2em 1em;    
            align-self: center;    
        }
    }
}