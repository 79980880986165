@use "sass:color";
@use "main";

section#BrandIdentity {
    position: relative;
    background: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 2em;
    z-index: 0;
    
    height: fit-content;
    
    font-size: $font-larger;
    padding: 1em;

    .identityElement {
        background-color: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        font-size: inherit;
        min-width: 300px;
        position: relative;
        max-width: 900px;
        width: 100%;
        gap: 1em;
        z-index: 10;
        height: fit-content;
        box-shadow: 2px 2px 12px 5px hsla(211, 53%, 17%, 0.1);
        font-size: $font-larger;
        padding: 1em;
        border-radius: 12px;
        opacity: 0;

        & > img {
            font-size: inherit;
            width: clamp(70px, 30vw, 250px);
        }

        & > div {
            position: relative;
        }

        h2 {
            font-size: $font-larger;
            margin: 0 0 1em 0;
            overflow: scroll;
        }

        p {
            font-size: $font-medium;
            overflow: hidden;

            transition: height 700ms;

            span {
                color: color.change($sekundär-blue, $alpha: 1);
            }
        }

        p[detailed] {
            visibility: hidden;
            height: fit-content;
            position: absolute;
        }

        @media screen and (min-width: 650px) {
            flex-direction: row;
            box-shadow: none;
            &:nth-child(2n) {
                flex-flow: row-reverse;
            }
            p { display: none; }
            p[detailed] {
                display: inline;
                visibility: visible; 
                position: relative;
            }
        }
    }

    @for $i from 0 through 4 {
        .identityElement.visible:nth-of-type(#{$i}) {
            animation: showIdentityElement 400ms ease-in-out #{($i - 1) * 300ms} 1 forwards;
        }
    }

    @keyframes showIdentityElement {
        from {
            transform: translateY(50px);
            opacity: 0;
        }
        to {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

