@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf);
  font-weight: Bold;
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Light.ttf);
  font-weight: Light;
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf);
  font-weight: normal;
}
* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  line-height: 120%;
}

html, body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

section {
  width: 100vw;
  height: 100vh;
}

.kontaktBtn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: hsl(15deg, 82%, 54%);
  font-size: 2.48832rem;
  color: white;
  z-index: 10;
}

.kontaktBtn.mobile {
  bottom: 10px;
  right: 10px;
  display: block;
  border-radius: 50%;
  box-sizing: content-box;
  width: 10vw;
  height: 10vw;
  padding: 0.5em;
  animation: ring 75ms ease-in-out 1000ms 6 alternate;
}
@media screen and (min-width: 750px) {
  .kontaktBtn.mobile {
    display: none;
  }
}

.kontaktBtn.desktop {
  border-radius: 12px;
  font-size: 2.985984rem;
  padding: 0.2em 1em;
}
@media screen and (max-width: 750px) {
  .kontaktBtn.desktop {
    display: none;
  }
}

@keyframes ring {
  to {
    transform: rotate(30deg);
  }
}
button {
  border: none;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#Wave1 {
  width: 100vw;
  height: 40vw;
}

footer {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  background: #fff;
}
footer a {
  text-decoration: none;
  font-size: 1.2rem;
  color: black;
  margin: 1em 0;
}

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf);
  font-weight: Bold;
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Light.ttf);
  font-weight: Light;
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf);
  font-weight: normal;
}
* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  line-height: 120%;
}

html, body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

section {
  width: 100vw;
  height: 100vh;
}

.kontaktBtn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: hsl(15deg, 82%, 54%);
  font-size: 2.48832rem;
  color: white;
  z-index: 10;
}

.kontaktBtn.mobile {
  bottom: 10px;
  right: 10px;
  display: block;
  border-radius: 50%;
  box-sizing: content-box;
  width: 10vw;
  height: 10vw;
  padding: 0.5em;
  animation: ring 75ms ease-in-out 1000ms 6 alternate;
}

@media screen and (min-width: 750px) {
  .kontaktBtn.mobile {
    display: none;
  }
}
.kontaktBtn.desktop {
  border-radius: 12px;
  font-size: 2.985984rem;
  padding: 0.2em 1em;
}

@media screen and (max-width: 750px) {
  .kontaktBtn.desktop {
    display: none;
  }
}
@keyframes ring {
  to {
    transform: rotate(30deg);
  }
}
button {
  border: none;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#Wave1 {
  width: 100vw;
  height: 40vw;
}

footer {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  background: #fff;
}

footer a {
  text-decoration: none;
  font-size: 1.2rem;
  color: black;
  margin: 1em 0;
}

section#Hero {
  background-color: hsla(210deg, 52%, 50%, 0.25);
  font-size: clamp(3.5831808rem, 6.5vw, 110px);
  position: relative;
  height: calc(50vh + 3.6em + 180px);
}
@media screen and (max-width: 650px) {
  section#Hero {
    font-size: clamp(0px, 10vw, 3.5831808rem);
  }
}
section#Hero h1 {
  font-size: inherit;
  position: absolute;
  text-align: right;
  color: hsl(210deg, 100%, 16%);
  right: 0;
  top: calc(136px + 3.456rem);
  margin: 0 1em 0 0;
}
@media screen and (max-width: 670px) {
  section#Hero h1 {
    top: 100px;
  }
}
section#Hero #Boot {
  transform-origin: 9.9% 25.7%;
  animation: wiggleBoat 2s ease-in-out infinite alternate;
}
@keyframes wiggleBoat {
  from {
    transform: rotate(-3deg);
  }
  to {
    transform: rotate(2deg);
  }
}
section#Hero svg {
  position: relative;
  font-size: inherit;
  align-self: flex-start;
  max-width: 450vw;
  min-width: 100vw;
  height: 50vh;
  left: 0;
  top: calc(180px + 3.6em);
}

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf);
  font-weight: Bold;
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Light.ttf);
  font-weight: Light;
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf);
  font-weight: normal;
}
* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  line-height: 120%;
}

html, body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

section {
  width: 100vw;
  height: 100vh;
}

.kontaktBtn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: hsl(15deg, 82%, 54%);
  font-size: 2.48832rem;
  color: white;
  z-index: 10;
}

.kontaktBtn.mobile {
  bottom: 10px;
  right: 10px;
  display: block;
  border-radius: 50%;
  box-sizing: content-box;
  width: 10vw;
  height: 10vw;
  padding: 0.5em;
  animation: ring 75ms ease-in-out 1000ms 6 alternate;
}

@media screen and (min-width: 750px) {
  .kontaktBtn.mobile {
    display: none;
  }
}
.kontaktBtn.desktop {
  border-radius: 12px;
  font-size: 2.985984rem;
  padding: 0.2em 1em;
}

@media screen and (max-width: 750px) {
  .kontaktBtn.desktop {
    display: none;
  }
}
@keyframes ring {
  to {
    transform: rotate(30deg);
  }
}
button {
  border: none;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#Wave1 {
  width: 100vw;
  height: 40vw;
}

footer {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  background: #fff;
}

footer a {
  text-decoration: none;
  font-size: 1.2rem;
  color: black;
  margin: 1em 0;
}

header {
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  font-size: 1.44rem;
  right: 0;
  z-index: 20;
  will-change: height;
}
header nav.mobile {
  display: none;
  position: relative;
  width: clamp(100px, 90vw, 500px);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 5px auto;
  height: 36px;
  justify-content: space-between;
  align-items: center;
}
@media screen and (max-width: 670px) {
  header nav.mobile {
    display: flex;
  }
}
header nav.mobile img {
  height: 100%;
}
header nav.mobile div#HamburgerContainer {
  position: relative;
  width: 36px;
  height: 60%;
  z-index: 20;
  border: 1px solid transparent;
}
header nav.mobile div#HamburgerContainer div.hamburger {
  height: 3px;
  border-radius: 2px;
  position: absolute;
  right: 0;
  background: black;
}
header nav.mobile div#HamburgerContainer div:nth-child(1) {
  top: 0;
  width: 100%;
}
header nav.mobile div#HamburgerContainer div:nth-child(2) {
  top: 48%;
  width: 75%;
}
header nav.mobile div#HamburgerContainer div:nth-child(3) {
  top: 96%;
  width: 50%;
}
@keyframes hamburgerTop {
  0% {
    transform-origin: center center;
    transform: translate(0, 0) rotate(0);
  }
  100% {
    transform: translate(0, 9px) rotate(135deg);
  }
}
@keyframes hamburgerMid {
  0% {
    transform-origin: center center;
    transform: translate(0, 0) rotate(0);
  }
  100% {
    transform: translate(-6.4px, -3.9px) rotate(-135deg);
  }
}
@keyframes hamburgerBottom {
  0% {
    transform-origin: center center;
    transform: translate(0, 0) rotate(0);
  }
  100% {
    transform: translate(-2.5px, -4.2px) rotate(225deg);
  }
}
header nav.mobile div#NavContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  position: fixed;
  gap: 1em;
  font-size: 1.728rem;
  width: clamp(22ch, 40vw, 400px);
  right: 0;
  top: 0;
  height: 100vh;
  background-color: #fff;
  transform: translateX(110vw);
  transition: 300ms ease-in-out;
  z-index: 15;
  padding: 3em 2em 4em 1em;
}
@supports (backdrop-filter: blur(18px)) or (-webkit-backdrop-filter: blur(18px)) {
  header nav.mobile div#NavContainer {
    background-color: hsla(15deg, 83%, 54%, 0.75);
    -webkit-backdrop-filter: blur(18px);
    backdrop-filter: blur(18px);
  }
}
header nav.mobile div#NavContainer h3 {
  margin: 0;
}
header nav.mobile div#NavContainer div:first-of-type {
  display: flex;
  gap: 1em;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
header nav.mobile div#NavContainer div:last-of-type a {
  text-align: right;
  text-decoration: none;
  display: block;
  color: rgba(0, 0, 0, 0.7);
  font-size: 1.2rem;
}
header nav.mobile div#NavContainer.open {
  transform: translateX(0vw);
}
header nav.desktop {
  position: relative;
  visibility: hidden;
  display: none;
  flex-direction: column;
  top: 0;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
  width: 90vw;
  left: 0;
  right: 0;
  margin: 8px auto;
  padding: 0 1em;
  transition: height 200ms linear;
  height: calc(100px + 3.456rem);
  will-change: height;
}
@media screen and (min-width: 670px) {
  header nav.desktop {
    visibility: visible;
    display: flex;
  }
}
header nav.desktop.small {
  height: 42px;
}
header nav.desktop img {
  height: 100px;
  transition: transform 200ms linear;
  flex-grow: 1;
  flex-shrink: 0;
  will-change: transform;
}
header nav.desktop div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 3em;
}
header nav.desktop div h3 {
  font-size: 1.44rem;
  overflow-y: hidden;
  margin-bottom: 0;
}
header nav.desktop div h3 span {
  transition: transform 200ms linear;
  display: block;
}
header nav.desktop div.small > h3 > span {
  transform: translateY(-2em);
}

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf);
  font-weight: Bold;
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Light.ttf);
  font-weight: Light;
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf);
  font-weight: normal;
}
* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  line-height: 120%;
}

html, body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

section {
  width: 100vw;
  height: 100vh;
}

.kontaktBtn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: hsl(15deg, 82%, 54%);
  font-size: 2.48832rem;
  color: white;
  z-index: 10;
}

.kontaktBtn.mobile {
  bottom: 10px;
  right: 10px;
  display: block;
  border-radius: 50%;
  box-sizing: content-box;
  width: 10vw;
  height: 10vw;
  padding: 0.5em;
  animation: ring 75ms ease-in-out 1000ms 6 alternate;
}

@media screen and (min-width: 750px) {
  .kontaktBtn.mobile {
    display: none;
  }
}
.kontaktBtn.desktop {
  border-radius: 12px;
  font-size: 2.985984rem;
  padding: 0.2em 1em;
}

@media screen and (max-width: 750px) {
  .kontaktBtn.desktop {
    display: none;
  }
}
@keyframes ring {
  to {
    transform: rotate(30deg);
  }
}
button {
  border: none;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#Wave1 {
  width: 100vw;
  height: 40vw;
}

footer {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  background: #fff;
}

footer a {
  text-decoration: none;
  font-size: 1.2rem;
  color: black;
  margin: 1em 0;
}

section#Leistungen {
  position: relative;
  background: hsla(15deg, 83%, 54%, 0.25);
  min-height: 60vh;
  height: fit-content;
}
section#Leistungen div#LeistungenContainer {
  position: relative;
  overflow-x: hidden;
  width: 100vw;
  margin: 2em 0;
}
section#Leistungen div#LeistungenContainer div.leistungElement.leistungElement {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: content-box;
}
@media screen and (max-width: 690px) {
  section#Leistungen div#LeistungenContainer div.leistungElement.leistungElement {
    width: clamp(200px, 80vw, 450px);
    padding: 0 calc((100vw - clamp(200px, 80vw, 450px)) / 2);
  }
}
@media screen and (min-width: 690px) and (max-width: 1300px) {
  section#Leistungen div#LeistungenContainer div.leistungElement.leistungElement {
    width: clamp(200px, 40vw, 450px);
    padding: 0 calc((50vw - clamp(200px, 40vw, 450px)) / 2);
  }
}
@media screen and (min-width: 1300px) and (max-width: 1700px) {
  section#Leistungen div#LeistungenContainer div.leistungElement.leistungElement {
    width: clamp(200px, 26.67vw, 450px);
    padding: 0 calc((33.33vw - clamp(200px, 26.67vw, 450px)) / 2);
  }
}
@media screen and (min-width: 1700px) {
  section#Leistungen div#LeistungenContainer div.leistungElement.leistungElement {
    width: clamp(200px, 20vw, 450px);
    padding: 0 calc((25vw - clamp(200px, 20vw, 450px)) / 2);
  }
}
section#Leistungen div#LeistungenContainer div.leistungElement.leistungElement > img {
  width: 60%;
}
section#Leistungen div#LeistungenContainer div.leistungElement.leistungElement > h2 {
  width: 90%;
}
section#Leistungen div#LeistungenContainer div.leistungElement.leistungElement > p {
  width: 75%;
}

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf);
  font-weight: Bold;
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Light.ttf);
  font-weight: Light;
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf);
  font-weight: normal;
}
* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  line-height: 120%;
}

html, body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

section {
  width: 100vw;
  height: 100vh;
}

.kontaktBtn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: hsl(15deg, 82%, 54%);
  font-size: 2.48832rem;
  color: white;
  z-index: 10;
}

.kontaktBtn.mobile {
  bottom: 10px;
  right: 10px;
  display: block;
  border-radius: 50%;
  box-sizing: content-box;
  width: 10vw;
  height: 10vw;
  padding: 0.5em;
  animation: ring 75ms ease-in-out 1000ms 6 alternate;
}

@media screen and (min-width: 750px) {
  .kontaktBtn.mobile {
    display: none;
  }
}
.kontaktBtn.desktop {
  border-radius: 12px;
  font-size: 2.985984rem;
  padding: 0.2em 1em;
}

@media screen and (max-width: 750px) {
  .kontaktBtn.desktop {
    display: none;
  }
}
@keyframes ring {
  to {
    transform: rotate(30deg);
  }
}
button {
  border: none;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#Wave1 {
  width: 100vw;
  height: 40vw;
}

footer {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  background: #fff;
}

footer a {
  text-decoration: none;
  font-size: 1.2rem;
  color: black;
  margin: 1em 0;
}

section#BrandIdentity {
  position: relative;
  background: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2em;
  z-index: 0;
  height: fit-content;
  font-size: 1.728rem;
  padding: 1em;
}
section#BrandIdentity .identityElement {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-size: inherit;
  min-width: 300px;
  position: relative;
  max-width: 900px;
  width: 100%;
  gap: 1em;
  z-index: 10;
  height: fit-content;
  box-shadow: 2px 2px 12px 5px hsla(211deg, 53%, 17%, 0.1);
  font-size: 1.728rem;
  padding: 1em;
  border-radius: 12px;
  opacity: 0;
}
section#BrandIdentity .identityElement > img {
  font-size: inherit;
  width: clamp(70px, 30vw, 250px);
}
section#BrandIdentity .identityElement > div {
  position: relative;
}
section#BrandIdentity .identityElement h2 {
  font-size: 1.728rem;
  margin: 0 0 1em 0;
  overflow: scroll;
}
section#BrandIdentity .identityElement p {
  font-size: 1.2rem;
  overflow: hidden;
  transition: height 700ms;
}
section#BrandIdentity .identityElement p span {
  color: #3e80c1;
}
section#BrandIdentity .identityElement p[detailed] {
  visibility: hidden;
  height: fit-content;
  position: absolute;
}
@media screen and (min-width: 650px) {
  section#BrandIdentity .identityElement {
    flex-direction: row;
    box-shadow: none;
  }
  section#BrandIdentity .identityElement:nth-child(2n) {
    flex-flow: row-reverse;
  }
  section#BrandIdentity .identityElement p {
    display: none;
  }
  section#BrandIdentity .identityElement p[detailed] {
    display: inline;
    visibility: visible;
    position: relative;
  }
}
section#BrandIdentity .identityElement.visible:nth-of-type(0) {
  animation: showIdentityElement 400ms ease-in-out -300ms 1 forwards;
}
section#BrandIdentity .identityElement.visible:nth-of-type(1) {
  animation: showIdentityElement 400ms ease-in-out 0ms 1 forwards;
}
section#BrandIdentity .identityElement.visible:nth-of-type(2) {
  animation: showIdentityElement 400ms ease-in-out 300ms 1 forwards;
}
section#BrandIdentity .identityElement.visible:nth-of-type(3) {
  animation: showIdentityElement 400ms ease-in-out 600ms 1 forwards;
}
section#BrandIdentity .identityElement.visible:nth-of-type(4) {
  animation: showIdentityElement 400ms ease-in-out 900ms 1 forwards;
}
@keyframes showIdentityElement {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf);
  font-weight: Bold;
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Light.ttf);
  font-weight: Light;
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf);
  font-weight: normal;
}
* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  line-height: 120%;
}

html, body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

section {
  width: 100vw;
  height: 100vh;
}

.kontaktBtn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: hsl(15deg, 82%, 54%);
  font-size: 2.48832rem;
  color: white;
  z-index: 10;
}

.kontaktBtn.mobile {
  bottom: 10px;
  right: 10px;
  display: block;
  border-radius: 50%;
  box-sizing: content-box;
  width: 10vw;
  height: 10vw;
  padding: 0.5em;
  animation: ring 75ms ease-in-out 1000ms 6 alternate;
}

@media screen and (min-width: 750px) {
  .kontaktBtn.mobile {
    display: none;
  }
}
.kontaktBtn.desktop {
  border-radius: 12px;
  font-size: 2.985984rem;
  padding: 0.2em 1em;
}

@media screen and (max-width: 750px) {
  .kontaktBtn.desktop {
    display: none;
  }
}
@keyframes ring {
  to {
    transform: rotate(30deg);
  }
}
button {
  border: none;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#Wave1 {
  width: 100vw;
  height: 40vw;
}

footer {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  background: #fff;
}

footer a {
  text-decoration: none;
  font-size: 1.2rem;
  color: black;
  margin: 1em 0;
}

#Wave2, #Wave3 {
  position: relative;
  align-self: flex-end;
  width: 100vw;
  height: 150px;
  z-index: 2;
}
@media screen and (min-width: 850px) {
  #Wave2, #Wave3 {
    width: 85vw;
  }
}

#Wave3 {
  transform: rotate(180deg);
}

section#Nutzen {
  position: relative;
  z-index: 2;
  align-self: flex-end;
  background: #d2e0f0;
  padding: 2em 2em 0 2em;
  font-size: 2.0736rem;
  height: fit-content;
  width: 100vw;
}
@media screen and (min-width: 850px) {
  section#Nutzen {
    width: 85vw;
  }
}
section#Nutzen h1, section#Nutzen h2, section#Nutzen h3 {
  width: fit-content;
  max-width: 850px;
}
section#Nutzen h1 {
  font-size: 3.5831808rem;
}
section#Nutzen h2 {
  font-size: 2.48832rem;
}
section#Nutzen h3 {
  font-size: 1.44rem;
}

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf);
  font-weight: Bold;
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Light.ttf);
  font-weight: Light;
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf);
  font-weight: normal;
}
* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  line-height: 120%;
}

html, body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

section {
  width: 100vw;
  height: 100vh;
}

.kontaktBtn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: hsl(15deg, 82%, 54%);
  font-size: 2.48832rem;
  color: white;
  z-index: 10;
}

.kontaktBtn.mobile {
  bottom: 10px;
  right: 10px;
  display: block;
  border-radius: 50%;
  box-sizing: content-box;
  width: 10vw;
  height: 10vw;
  padding: 0.5em;
  animation: ring 75ms ease-in-out 1000ms 6 alternate;
}

@media screen and (min-width: 750px) {
  .kontaktBtn.mobile {
    display: none;
  }
}
.kontaktBtn.desktop {
  border-radius: 12px;
  font-size: 2.985984rem;
  padding: 0.2em 1em;
}

@media screen and (max-width: 750px) {
  .kontaktBtn.desktop {
    display: none;
  }
}
@keyframes ring {
  to {
    transform: rotate(30deg);
  }
}
button {
  border: none;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#Wave1 {
  width: 100vw;
  height: 40vw;
}

footer {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  background: #fff;
}

footer a {
  text-decoration: none;
  font-size: 1.2rem;
  color: black;
  margin: 1em 0;
}

section#Referenzen {
  position: relative;
  height: fit-content;
  margin: -20em 0 0 0;
  padding: 24em 0 0 0;
  background-color: hsla(142deg, 78%, 36%, 0.25);
  z-index: 1;
}
section#Referenzen div.referenzenElement.referenzenElement {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background: hsla(0deg, 0%, 100%, 0.75);
  height: 10em;
  font-size: 1.728rem;
  width: 17ch;
  max-width: 90vw;
  margin: 20px;
  border-radius: 12px;
  padding: 10px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -4px rgba(0, 0, 0, 0.1);
}
section#Referenzen div.referenzenElement.referenzenElement > img {
  width: 80%;
  height: 100px;
  object-fit: contain;
}
section#Referenzen div.referenzenElement.referenzenElement h2 {
  font-size: inherit;
  text-align: center;
}
section#Referenzen div.referenzenElement.referenzenElement p {
  font-size: 1.2rem;
}
section#Referenzen div.referenzenElement.referenzenElement:nth-of-type(1) {
  justify-content: center;
}
section#Referenzen div.referenzenElement.referenzenElement:nth-of-type(1) img {
  width: 25%;
  border-radius: 0;
}

img#Waves3 {
  margin: 0 0 0 0;
  z-index: 2;
  width: 700px;
  min-width: 100vw;
  align-self: flex-start;
  height: fit-content;
  object-fit: fill;
}

@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Bold.ttf);
  font-weight: Bold;
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Light.ttf);
  font-weight: Light;
}
@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf);
  font-weight: normal;
}
* {
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  line-height: 120%;
}

html, body {
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

section {
  width: 100vw;
  height: 100vh;
}

.kontaktBtn {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: hsl(15deg, 82%, 54%);
  font-size: 2.48832rem;
  color: white;
  z-index: 10;
}

.kontaktBtn.mobile {
  bottom: 10px;
  right: 10px;
  display: block;
  border-radius: 50%;
  box-sizing: content-box;
  width: 10vw;
  height: 10vw;
  padding: 0.5em;
  animation: ring 75ms ease-in-out 1000ms 6 alternate;
}
@media screen and (min-width: 750px) {
  .kontaktBtn.mobile {
    display: none;
  }
}

.kontaktBtn.desktop {
  border-radius: 12px;
  font-size: 2.985984rem;
  padding: 0.2em 1em;
}
@media screen and (max-width: 750px) {
  .kontaktBtn.desktop {
    display: none;
  }
}

@keyframes ring {
  to {
    transform: rotate(30deg);
  }
}
button {
  border: none;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

#Wave1 {
  width: 100vw;
  height: 40vw;
}

footer {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  background: #fff;
}
footer a {
  text-decoration: none;
  font-size: 1.2rem;
  color: black;
  margin: 1em 0;
}

section#Kontakt {
  position: relative;
  background-color: hsla(210deg, 52%, 50%, 0.25);
  height: max-content;
  padding: 40px;
}
section#Kontakt > div {
  bottom: 0;
  width: 80vw;
  max-width: 800px;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 40px;
  font-size: 1rem;
}
section#Kontakt > div h1 {
  grid-area: header;
  margin: 0 0 0 -1ch;
  font-size: 2.985984rem;
}
section#Kontakt > div h3 {
  font-weight: normal;
  font-size: 1.2rem;
  grid-area: text;
  margin: 0;
}
section#Kontakt > div > div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 40px;
}
section#Kontakt > div > div > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 24ch;
  font-size: 1rem;
  margin: auto;
}
section#Kontakt > div > div > div img {
  width: 60%;
}
section#Kontakt > div > div > div p, section#Kontakt > div > div > div a {
  margin-block-start: 1em;
  text-decoration: none;
  color: black;
}
section#Kontakt > div div:nth-of-type(1) {
  grid-area: nachricht;
}
section#Kontakt > div div:nth-of-type(2) {
  grid-area: tele;
}
section#Kontakt > div div:nth-of-type(3) {
  grid-area: mail;
}
section#Kontakt > div p {
  grid-area: abschied;
  text-align: center;
  margin: 0;
}
section#Kontakt > div button {
  background-color: hsl(15deg, 82%, 54%);
  font-size: 2.48832rem;
  color: white;
  z-index: 10;
  width: min-content;
  border-radius: 12px;
  padding: 0.2em 1em;
  align-self: center;
}

