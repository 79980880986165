@use "main";

#Wave2, #Wave3 {
    position: relative;
    align-self: flex-end;
    width: 100vw;
    @media screen and (min-width: 850px) {
        width: 85vw;
    }
    height: 150px;
    z-index: 2;
}

#Wave3 {
    transform: rotate(180deg);
}

section#Nutzen {
    position: relative;
    z-index: 2;
    align-self: flex-end;
    background: #d2e0f0;
    padding: 2em 2em 0 2em;
    font-size: $font-big;
    height: fit-content;
    width: 100vw;
    @media screen and (min-width: 850px) {
        width: 85vw;
    }

    h1, h2, h3 {
        width: fit-content;
        max-width: 850px;
    }

    h1 {
        font-size: $font-humongous;
    }

    h2 {
        font-size: $font-bigger;
    }

    h3 {
        font-size: $font-large;
    }
}